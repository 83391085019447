'use client';
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useRouter } from 'next/navigation';

interface User {
  id: number;
  username: string;
  email: string;
}

type AuthContextType = {
  isLoggedIn: boolean;
  user: User | null;
  login: (accessToken: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const router = useRouter();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsLoggedIn(true);
      // プロフィール取得エンドポイントを修正
      axiosInstance
        .get('/auth/profile/', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          setUser(response.data);
        })
        .catch(() => {
          setIsLoggedIn(false);
          setUser(null);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
        });
    }
  }, []);

  const login = (accessToken: string) => {
    localStorage.setItem('access_token', accessToken);
    setIsLoggedIn(true);
    // プロフィール取得エンドポイントを修正
    axiosInstance
      .get('/auth/profile/', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(response => {
        setUser(response.data);
      })
      .catch(() => {
        setIsLoggedIn(false);
        setUser(null);
        localStorage.removeItem('access_token');
      });
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    setUser(null);
    router.push('/login');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

